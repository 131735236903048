//
//
// ==================== CommonItems module ==================== 
//
//
module CommonItems{
    export var $window:JQuery=$(window);
    export var $document:JQuery=$(document);    
    export var $body:JQuery=$("body");      
    export var $modal:JQuery=$("#modal");
    export var $modalWrapper:JQuery=$("#modal_rapper");     
    export var $modalCloseBtn:JQuery=$("#modal_close_btn").find("a"); 
    export var $modalBg:JQuery=$("#modal_bg");
    export function openModal(_onStart=undefined,_onComplete=undefined):void{
        if(_onStart){
            _onStart();    
        }
        $modal.css({display:"block"}); 
        TweenLite.to($modal, 0.3, {autoAlpha:1,onComplete:_onComplete});
    }
    export function closeModal(_onStart=undefined,_onComplete=undefined):void{
        if(_onStart){
            _onStart();    
        }
        $modalBg.unbind();
        $modalCloseBtn.unbind();
        TweenLite.to($modalCloseBtn,0.2,{alpha:0});
        TweenLite.to($modal, 0.3, {autoAlpha:0,onComplete:_onComplete});
    }
    export function adjustHeight(_height:number):void{
        $document.css({height:_height});
        $body.css({height:_height});
    }
}

(function($) {
    //---------- callbacks ----------
    var _onModalCloseStart=():void=>{
        //console.log("_onModalCloseStart!!");
        CommonItems.$window.unbind("resize")
    }
    var _onModalCloseComplete=():void=>{
        //console.log("_onModalCloseComplete!!");
        CommonItems.$document.css({height:"auto",overflow:"auto"});
        CommonItems.$body.css({height:"auto",overflow:"auto"});
        CommonItems.$document.css({height:"100%"});
    }
    var _onModalOpenStart=():void=>{
        //console.log("_onModalOpenStart!!");
        CommonItems.adjustHeight(CommonItems.$window.height());
        CommonItems.$document.css({overflow:"hidden"});
        CommonItems.$body.css({overflow:"hidden"});
    }
    var _onModalOpenComplete=():void=>{
        //console.log("_onModalOpenComplete!!");
        CommonItems.$modalBg.bind("click",():void=>{
            CommonItems.closeModal(_onModalCloseStart,_onModalCloseComplete);        
        });
        //---------- mouseEvents ----------
        CommonItems.$modalCloseBtn.bind({
            "click":(e: JQueryEventObject):void=>{
                e.preventDefault();
                e.stopPropagation();
                //////////console.log(e.type,e.currentTarget);
                CommonItems.closeModal(_onModalCloseStart,_onModalCloseComplete); 
            }      
        }); 
        //---------- resizeEvent ----------
        CommonItems.$window.bind("resize",(e:JQueryEventObject):void=>{
            CommonItems.adjustHeight(CommonItems.$window.height());
        });
    }
    
    CommonItems.openModal(_onModalOpenStart,_onModalOpenComplete);
})(jQuery);

